export const GATrackingCodeCRSAO = "G-NLXR152G66"
export const GATrackingCodeVantage = "G-7DV1N04HS9"
export const GATrackingCodeCandidATSGrad = "G-9YC2G3BFPH"
export const GATrackingCodeCandidX = "G-0ZSYPP7YHM"
export const GATrackingCodeTest = "G-5QLCQY22E8"
export const GATrackingCodeLateral = "G-7Z0R4JN7SM"

export function getConfig(env) {
  let configObj = {}

  switch (env) {
    case "uidev":
      configObj = {
        BASE_DOMAIN: "rdev.co.uk",
      }
      break
    case "uistaging":
      configObj = {
        BASE_DOMAIN: "rstaging.co.uk",
      }
      break
    case "candid":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "http://localhost:3001/vantage",
        PROFILE_URL: "http://localhost:3000",
        BASE_DOMAIN: "rdev.co.uk",
        level: "LOCAL",
        INTERNAL: true,
        email: "support@candidx.io",
        team: "The Rare team",
        domain: "candidx.io",
      }
      break
    case "candiddev":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-beta.candidx.io/vantage",
        PROFILE_URL: "https://beta.candidx.io",
        BASE_DOMAIN: "rdev.co.uk",
        INTERNAL: true,
        level: "DEV",
        email: "support@candidx.io",
        team: "The Rare team",
        domain: "candidx.io",
      }
      break
    case "candidstaging":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-staging.candidx.io/vantage",
        PROFILE_URL: "https://staging.candidx.io",
        BASE_DOMAIN: "rstaging.co.uk",
        level: "STAGING",
        INTERNAL: true,
        email: "support@candidx.io",
        team: "The Rare team",
        domain: "candidx.io",
      }
      break
    case "candidprod":
      configObj = {
        GA: GATrackingCodeCandidX,
        BASE_URL: "https://api-prod.candidx.io/vantage",
        PROFILE_URL: "https://candidx.io",
        level: "PROD",
        BASE_DOMAIN: "candidx.io",
        INTERNAL: true,
        email: "support@candidx.io",
        team: "The Rare team",
        domain: "candidx.io",
      }
      break
    case "vantage":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "http://localhost:3001/vantage",
        PROFILE_URL: "http://localhost:3000",
        BASE_DOMAIN: "rdev.co.uk",
        level: "LOCAL",
        EXTERNAL: true,
        email: "info@vantageapp.io",
        team: "The Vantage team",
      }
      break
    case "vantagedev":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-beta.candidats.io/vantage",
        PROFILE_URL: "https://beta-portal.vantageapp.io",
        BASE_DOMAIN: "rdev.co.uk",
        level: "DEV",
        EXTERNAL: true,
        email: "info@vantageapp.io",
        team: "The Vantage team",
      }
      break
    case "vantagestaging":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-staging.candidats.io/vantage",
        PROFILE_URL: "https://staging.vantageapp.io",
        BASE_DOMAIN: "rstaging.co.uk",
        level: "STAGING",
        EXTERNAL: true,
        email: "info@vantageapp.io",
        team: "The Vantage team",
      }
      break
    case "vantageprod":
      configObj = {
        GA: GATrackingCodeVantage,
        BASE_URL: "https://api-prod.candidats.io/vantage",
        PROFILE_URL: "https://portal.vantageapp.io",
        level: "PROD",
        EXTERNAL: true,
        email: "info@vantageapp.io",
        team: "The Vantage team",
      }
      break
    case "demo":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-demo.vantageapp.io/vantage",
        PROFILE_URL: "https://demo.vantageapp.io",
        level: "PROD",
        EXTERNAL: true,
        email: "info@vantageapp.io",
        team: "The Vantage team",
      }
      break
    case "ats":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "http://localhost:3001/vantage",
        PROFILE_URL: "http://localhost:3000",
        level: "LOCAL",
        BASE_DOMAIN: "rdev.co.uk",
        EXTERNAL: true,
        email: "support@candidats.io",
        team: "The Candid ATS team",
        domain: "candidats.io",
      }
      break
    case "atsdev":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-beta.candidats.io/vantage",
        PROFILE_URL: "https://beta-portal.vantageapp.io",
        level: "DEV",
        BASE_DOMAIN: "rdev.co.uk",
        EXTERNAL: true,
        email: "support@candidats.io",
        team: "The Candid ATS team",
        domain: "candidats.io",
      }
      break
    case "atsstaging":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-staging.candidats.io/vantage",
        PROFILE_URL: "https://staging.vantageapp.io",
        BASE_DOMAIN: "rstaging.co.uk",
        level: "STAGING",
        EXTERNAL: true,
        email: "support@candidats.io",
        team: "The Candid ATS team",
        domain: "candidats.io",
      }
      break
    case "atsprod":
      configObj = {
        GA: GATrackingCodeCandidATSGrad,
        BASE_URL: "https://api-prod.candidats.io/vantage",
        PROFILE_URL: "https://portal.vantageapp.io",
        level: "PROD",
        EXTERNAL: true,
        BASE_DOMAIN: "candidats.io",
        email: "support@candidats.io",
        team: "The Candid ATS team",
        domain: "candidats.io",
      }
      break
    case "lateral":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "http://localhost:3001/vantage",
        level: "LOCAL",
        BASE_DOMAIN: "rdev.co.uk",
        LATERAL: true,
        email: "info@vantageapp.io",
        team: "The Vantage team",
        domain: "candidats.io",
      }
      break
    case "lateraldev":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-betal.candidats.io/vantage",
        BASE_DOMAIN: "rdev.co.uk",
        level: "DEV",
        LATERAL: true,
        email: "support@candidats.io",
        team: "The Candid ATS team",
        domain: "candidats.io",
      }
      break
    case "lateralstaging":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-stagingl.candidats.io/vantage",
        level: "STAGING",
        BASE_DOMAIN: "rstaging.co.uk",
        LATERAL: true,
        email: "support@lateral.io",
        team: "The Lateral team",
        domain: "candidats.io",
      }
      break
    case "lateralprod":
      configObj = {
        GA: GATrackingCodeLateral,
        BASE_URL: "https://api-prodlateral.candidats.io/vantage",
        level: "PROD",
        BASE_DOMAIN: "candidats.io",
        LATERAL: true,
        email: "support@candidats.io",
        team: "The Candid ATS team",
        domain: "candidats.io",
      }
      break
    case "crsao":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "http://localhost:3001/vantage",
        BASE_DOMAIN: "rdev.co.uk",
        level: "LOCAL",
        CRSAO: true,
        email: "support@contextualrecruitment.com",
        team: "The Rare CRS team",
        domain: "contextualrecruitment.com",
      }
      break
    case "crsaodev":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-beta.contextualrecruitment.com/vantage",
        BASE_DOMAIN: "rdev.co.uk",
        level: "DEV",
        CRSAO: true,
        email: "support@contextualrecruitment.com",
        team: "The Rare CRS team",
        domain: "contextualrecruitment.com",
      }
      break
    case "crsaostaging":
      configObj = {
        GA: GATrackingCodeTest,
        BASE_URL: "https://api-staging.contextualrecruitment.com/vantage",
        BASE_DOMAIN: "rstaging.co.uk",
        level: "STAGING",
        CRSAO: true,
        email: "support@contextualrecruitment.com",
        team: "The Rare CRS team",
        domain: "contextualrecruitment.com",
      }
      break
    case "crsaoprod":
      configObj = {
        GA: GATrackingCodeCRSAO,
        BASE_URL: "https://api-prod.contextualrecruitment.com/vantage",
        BASE_DOMAIN: "contextualrecruitment.com",
        level: "PROD",
        CRSAO: true,
        email: "support@contextualrecruitment.com",
        team: "The Rare CRS team",
        domain: "contextualrecruitment.com",
      }
      break
    default:
      configObj = {}
      break
  }

  return configObj
}

const getEnv = () => {
  const env = process.env.REACT_APP_API_ENV
  const host = window.location.hostname
  const local = getConfig(env).level === "LOCAL"
  if (
    !local &&
    (host.endsWith("rdev.co.uk") || host.endsWith("rstaging.co.uk"))
  ) {
    //pull environment from URL
    const tokens = host.split(".")
    return `${tokens[0].split("-")[1]}${tokens[2].substring(1)}`
  }
  return env
}

export const team = getConfig(getEnv()).team
export const email = getConfig(getEnv()).email
export const INTERNAL = getConfig(getEnv()).INTERNAL

export const profileUrl = getConfig(getEnv()).PROFILE_URL
export const baseURL =
  window.location.host.includes(".hr.") ||
  window.location.host.includes(".hrx.") ||
  INTERNAL ||
  window.location.origin.replace("www.", "") === getConfig(getEnv()).PROFILE_URL
    ? getConfig(getEnv()).BASE_URL
    : getConfig(getEnv()).BASE_URL.replace("api", "apiapp")

export const baseDomain = getConfig(getEnv()).BASE_DOMAIN
export const candidateDomain = getConfig(getEnv()).domain

export const EXTERNAL = getConfig(getEnv()).EXTERNAL
export const LATERAL = getConfig(getEnv()).LATERAL
export const CRSAO = getConfig(getEnv()).CRSAO

export const isDev =
  getConfig(getEnv()).level === "LOCAL" || getConfig(getEnv()).level === "DEV"
export const isStaging = getConfig(getEnv()).level === "STAGING"
export const isProd = getConfig(getEnv()).level === "PROD"
export const isLocal = getConfig(getEnv()).level === "LOCAL"

export const gaCode = getConfig(getEnv()).GA
