import {
  SOVA_ASSESSMENT_CENTRE_RESPONSES_PREFIX,
  SEPARATOR,
  INTERVIEW_TYPE,
  PERCENT_TYPE,
  NUMBER_TYPE,
  BOOLEAN_TYPE,
  STRING_TYPE,
  DATE_TYPE,
  BASE_ASSESSMENT_TYPES,
} from "utils/constants"
import {
  formatPercentage,
  toFixedIfNumberHasDecimals,
  isBooleanTrue,
} from "utils/utils"
import { format } from "date-fns"

export const isOfInterviewTypeAtStage = (interviews, interviewType, stage) => {
  const stagesAt =
    BASE_ASSESSMENT_TYPES.find(x => x.id === interviewType)?.stages || []
  return (
    stagesAt.includes(stage?.stage?.id) &&
    interviews?.find(
      interview =>
        interview.stage?.stage?.id === stage.id &&
        interview.type === interviewType
    )
  )
}

const getSOVAScores = data => {
  return data.responses
    .filter(response =>
      response.questionId.startsWith(SOVA_ASSESSMENT_CENTRE_RESPONSES_PREFIX)
    )
    .map(activity => {
      return {
        key: activity.question
          .replace(SOVA_ASSESSMENT_CENTRE_RESPONSES_PREFIX, "")
          .replace(SEPARATOR, " - "),
        value: activity.response,
      }
    })
}

const getMetricForScore = (scoreDef, score) => {
  return scoreDef?.metrics?.findIndex(
    metric => (metric.key || metric.label) === score.key
  )
}

export const getBreakdownScoresForStage = (
  data,
  scoreDef,
  interviews,
  stage
) => {
  const sovaScores = getSOVAScores(data)

  const isSOVA = isOfInterviewTypeAtStage(
    interviews,
    INTERVIEW_TYPE.SOVA,
    stage
  )

  return isSOVA
    ? sovaScores
    : data.allScores
        .filter(x => x.stageId === stage?.id)
        .filter(_ => !scoreDef.showOnInvite)
        .sort((score1, score2) => {
          const score1Index = getMetricForScore(scoreDef, score1)
          const score2Index = getMetricForScore(scoreDef, score2)
          return score1Index < score2Index ? -1 : 1
        })
}

export const getScoreDefinition = (data, scoreDef, interviews, stage) => {
  const sovaScores = getSOVAScores(data)

  const sovaScoreDef = {
    ...scoreDef,
    metricType: "number",
    metrics: sovaScores.map(response => ({
      label: response.key,
    })),
  }

  const isSOVA = isOfInterviewTypeAtStage(
    interviews,
    INTERVIEW_TYPE.SOVA,
    stage
  )
  return isSOVA ? sovaScoreDef : scoreDef
}

export const getCandidateScore = (data, scoreDef, interviews, stage) => {
  const candidateScoreAtStage = data.candidateScores.find(
    x => x.stage.id === scoreDef?.stageId
  )
  const isSOVA = isOfInterviewTypeAtStage(
    interviews,
    INTERVIEW_TYPE.SOVA,
    stage
  )
  let candidateScore = candidateScoreAtStage?.score
  if (candidateScore && isSOVA) {
    return toFixedIfNumberHasDecimals(candidateScore)
  }
  if (scoreDef?.metricType === "percent") {
    return formatPercentage(candidateScore)
  }
  return candidateScore
}

export const getScoresForStage = (configuration, substage) => {
  return configuration?.scores?.find(
    score => score.stageId === substage?.stage.id
  )
}

export const isMetricNumber = (scoreDef, metric) => {
  return !scoreDef.metricType ||
    scoreDef.metricType.toUpperCase() === NUMBER_TYPE
    ? !metric?.metricType || metric?.metricType?.toUpperCase() === NUMBER_TYPE
    : metric?.metricType?.toUpperCase() === NUMBER_TYPE
}

export const getTotalScores = (scoreDef, scores) => {
  const totalScoreArray = scores
    ?.filter(x => x.value)
    .filter(x => {
      const scoreMetric = scoreDef?.metrics?.find(
        m => m.label === x.key || m === x.key
      )
      return isMetricNumber(scoreDef, scoreMetric)
    })

  return totalScoreArray?.reduce((a, b) => a + parseFloat(b.value), 0)
}

export const getMetricMin = (def, metric) => {
  if (metric?.min !== undefined) return parseFloat(metric.min, 10)
  if (def?.min !== undefined) return parseFloat(def.min, 10)
  switch (def?.metricType || metric?.metricType) {
    case "number":
    case "percent":
      return 0
    default:
      return undefined
  }
}

export const getMetricMax = (def, metric) => {
  if (metric?.max !== undefined) return parseFloat(metric.max, 10)
  if (def?.max !== undefined) return parseFloat(def.max, 10)
  switch (def?.metricType || metric?.metricType) {
    case "number":
      return 5
    case "percent":
      return 100
    default:
      return undefined
  }
}

export const getMetricType = (def, metric) => {
  if (metric.metricType === "percent") return "number"
  return metric.metricType || def.metricType || "text"
}

export const formatScore = (scoreDef, metric, score) => {
  const metricType = (metric.metricType || scoreDef.metricType)?.toUpperCase()
  if (metricType === PERCENT_TYPE) {
    return `${score?.value && `${score.value}%`}`
  } else if (metricType === BOOLEAN_TYPE) {
    return isBooleanTrue(score?.value) ? "Yes" : "No"
  } else if (metricType === STRING_TYPE) {
    return score?.value
  } else if (metricType === DATE_TYPE) {
    return score?.value && format(new Date(score?.value * 1000), "d MMMM yyyy")
  } else {
    const metricMax = getMetricMax(scoreDef, metric)
    return `${score?.value || ""} ${metricMax ? `/ ${metricMax}` : ""}`
  }
}

export const getMaxPossibleScore = scoreDef => {
  let totalPossible = 0

  scoreDef?.metrics
    .filter(metric => isMetricNumber(scoreDef, metric))
    .forEach(x => {
      totalPossible += getMetricMax(scoreDef, x) || 0
    })
  return totalPossible
}
